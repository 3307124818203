<template>
  <div class="wx_base">
    <p>微信小程序基础设置功能开发过程中...即将开放敬请期待</p>
  </div>
</template>

<script>
export default {
  name: 'wx_base'
}
</script>

<style>

</style>
